var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vx-card",
    {
      attrs: {
        title:
          _vm.id === null ? _vm.$t("course_create") : _vm.$t("course_edit"),
      },
    },
    [
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full" },
          [
            _vm.id === null
              ? _c("PlanUsageAlert", { attrs: { target: "course" } })
              : _vm._e(),
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|max:300",
                  expression: "'required|max:300'",
                },
              ],
              staticClass: "w-full",
              attrs: { label: _vm.$t("name"), name: "name" },
              model: {
                value: _vm.name,
                callback: function ($$v) {
                  _vm.name = $$v
                },
                expression: "name",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("name"),
                    expression: "errors.has('name')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("name")))]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-full" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|max:300",
                  expression: "'required|max:300'",
                },
              ],
              staticClass: "w-full",
              attrs: { label: _vm.$t("description"), name: "description" },
              model: {
                value: _vm.description,
                callback: function ($$v) {
                  _vm.description = $$v
                },
                expression: "description",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("description"),
                    expression: "errors.has('description')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("description")))]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full" },
          [
            _c(
              "vs-button",
              {
                staticClass: "float-right",
                attrs: { color: "primary", type: "border" },
                on: { click: _vm.cancel },
              },
              [_vm._v(_vm._s(_vm.$t("action.cancel")))]
            ),
            _c(
              "vs-button",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: "courses.create",
                    expression: "'courses.create'",
                  },
                ],
                staticClass: "float-right mr-2",
                attrs: { disabled: !_vm.validateForm },
                on: { click: _vm.create },
              },
              [_vm._v(_vm._s(_vm.$t("action.save")))]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }