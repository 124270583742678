<template>
  <vx-card :title="id === null ? $t('course_create') : $t('course_edit')">
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <PlanUsageAlert target="course" v-if="id === null"/>
        <vs-input
          class="w-full"
          :label="$t('name')"
          v-model="name"
          name="name"
          v-validate="'required|max:300'"
        />
        <span class="text-danger text-sm" v-show="errors.has('name')">{{
          errors.first('name')
        }}</span>
      </div>
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          :label="$t('description')"
          v-model="description"
          name="description"
          v-validate="'required|max:300'"
        />
        <span class="text-danger text-sm" v-show="errors.has('description')">{{
          errors.first('description')
        }}</span>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button
          class="float-right"
          color="primary"
          type="border"
          @click="cancel"
          >{{ $t('action.cancel') }}</vs-button
        >
        <vs-button
          v-permission="'courses.create'"
          class="float-right mr-2"
          @click="create"
          :disabled="!validateForm"
          >{{ $t('action.save') }}</vs-button
        >
      </div>
    </div>
  </vx-card>
</template>

<script>
import CourseService from '@/services/api/CourseService'

import PlanUsageAlert from '@/components/finance/PlanUsageAlert'

export default {
  components: {
    PlanUsageAlert
  },
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    id: null,
    name: '',
    service: null,
    description: ''
  }),
  computed: {
    validateForm() {
      return !this.isEmpty(this.name)
    }
  },
  methods: {
    create() {
      this.clearErrors(this.$validator)
      this.$vs.loading()

      this.service
        .createOrUpdate({
          id: this.id,
          name: this.name,
          description: this.description
        })
        .then(
          data => {
            this.$vs.loading.close()
            this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
            this.$router.push(`/courses/${data.id}/edit`)
          },
          error => {
            this.$vs.loading.close()
            this.showErrors(this.$validator, error)
            this.notifyError(
              this.$vs,
              this.$t('nao-foi-possivel-realizar-esta-operacao')
            )
          }
        )
    },
    cancel() {
      this.$router.push('/courses')
    },
    getData(id) {
      this.$vs.loading()
      this.service.read(id).then(
        data => {
          this.name = data.name
          this.description = data.description
          this.$vs.loading.close()
        },
        error => {
          this.$vs.loading.close()
        }
      )
    }
  },
  beforeMount() {
    this.service = CourseService.build(this.$vs)
    if (this.id) {
      this.getData(this.id)
    }
  }
}
</script>
